import React from "react";
import config from "../../config/main.config";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import history from "../../helper/browserHistory";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";

const namespace = "BuergerStiftung"
const BuergerStiftungRavensburg = (props) => {
    const {textStore} = props;

    return (
        <div className={'grid ui'}>
            <TitleImageComponent
                textStore={textStore}
                namespace={namespace}
                imageLinkId={`${namespace}-title-image-id`}
                headerId={`${namespace}-title-header-id`}
                subheaderId={`${namespace}-subtitle-header-id`}
            />
            <div className={'row centered'}>
                <div className={'fourteen wide computer sixteen wide tablet mobile column'}>
                    <Breadcrumb className={"breadcrumb-container"}>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            history.push("/");
                        }}>
                            HOME</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            history.push("/partner");
                        }}>
                            PARTNER</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section
                            className={"active-breadcrumb nav-item-green"}>Bürgerstiftung Ravensburg</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'seven wide computer fifteen wide tablet mobile column'}>
                    <EditHTMLComponent namespace={namespace} textStore={textStore} id={`${namespace}-text-1`}/>
                    <img src={config.BASE_IMAGE_URL + 'Logo_BSRV_rgb.png'} alt={'Bürgerstiftung Ravensburg'}/>
                </div>
                <div className={'seven wide computer fifteen wide tablet mobile column'}>
                    <img src={config.BASE_IMAGE_URL + 'site-images/1_Rikscha_Bruderhaus.jpg'} alt={'Bürgerstiftung Ravensburg'}/>
                </div>
            </div>
        </div>
    )
}

export default BuergerStiftungRavensburg